import gql from 'graphql-tag'

export const SESSION_LINK_MUTATION = gql`
  mutation SessionLinkMutation {
    sessionLink
  	{
      isLoggedIn
      userId
      userName
      picture
      lastLoginTime
    }
  }
`

export const SESSION_LOGOUT_MUTATION = gql`
  mutation SessionLogoutMutation {
    sessionLogout
  	{
      isLoggedIn
    }
  }
`

export const ALL_VOLUNTEERS_QUERY = gql`
  query AllVolunteersQuery {
    volunteers {
      volunteers {
        id
        account
        name
        gender
        mobile
        email
        cityArea
        vipLevel
      }
    }
  }
`

export const FUZZY_SEARCH_QUERY = gql`
  query FuzzySearch($account: String, $name: String) {
    volunteers {
      fuzzySearch(account: $account, name: $name) {
        id
        account
        name
      }
    }
  }
`

export const VOLUNTEER_QUERY = gql`
  query VolunteerQuery($id: Int, $account: String, $email: String, $name: String) {
    volunteers {
      volunteer(id: $id, account: $account, email: $email, name: $name) {
        id
        account
        name
        gender
        mobile
        email
        bonus
        redPoint
        birthday
        age
        pictureLink
        cityArea
        address
        reciveTime
        orderRemarks
        remarks
        vipLevel
        vipStartTime
        vipEndTime
        vipCreateTime
        createTime
        updateTime
        disableTime
        isStopCashOnDelivery
        isStopCashOnDeliveryDateTime
        isStopPayByCash
        isStopPayByCashDateTime
        internalRemarkRecords {
          remark
          createTime
          createUser
        }
      }
    }
  }
`

export const VOLUNTEER_ORDERS_QUERY = gql`
  query VolunteerOrdersQuery($id: Int) {
    volunteers {
      orders(id: $id) {
        id
        orderNumber
        name
        mobile
        receiverName
        receiverMobile
        address
        reciveTimeChoice
        remarks
        remarkFromAdmin
        customerServiceRemark
        weekType
        subscribe
        sub_week
        direction
        totalPrice
        bonus
        isPay
        isBaby
        isXmas
        paymentType
        paymentTime
        blackCatNum
        blackCatStatus
        ordersFrom
        ordersTime
        ordersStates
        deliveryType
        temperatureLayer
        paymentGateWayType
        createTime
        updateTime
        orderDetails {
          id
          amount
          price
          product {
            name
            type
            type2
          }
          productDetail {
            detail
            amt
            module
            price
            disc_price
          }
        }
    }
  }
}
`

export const VOLUNTEER_ORDERCOMPLAINTS_QUERY = gql`
  query VolunteerOrderComplaintsQuery($id: Int) {
    volunteers {
      orderComplaints(id: $id) {
        id
        orderNumber
        orderId
        orderTotalPrice
        complaintDate
        productName
        contactName
        contactMobile
        issue
        solution
        insideProcess
        bonusType
        bonus
        customerServiceId
        owner
        user
        status
        finishTime
      }
  }
}
`

export const VOLUNTEER_COUPON_QUERY = gql`
  query VolunteerCouponQuery($id: Int) {
    volunteers {
      coupon(id: $id) {
        origin
        discount
        startTime
        endTime
        isPercent
        amount
        count
        usageConstraint
        typeConstraint
      }
  }
}
`
export const VOLUNTEER_BONUS_QUERY = gql`
  query VolunteerBonusQuery($id: Int) {
    volunteers {
      bonus(id: $id) {
        id
        orderNumber
        calu
        remark
        createTime
        createUserName
      }
  }
}
`

export const VOLUNTEER_REDPOINT_QUERY = gql`
  query VolunteerRedPointQuery($id: Int) {
    volunteers {
      redpoint(id: $id) {
        redPointType
        orderNumber
        point
        remark
        createTime
        createUserName
      }
  }
}
`

export const VOLUNTEER_TICKET_QUERY = gql`
  query VolunteerTicketQuery($id: Int) {
    volunteers {
      tickets(id: $id) {
        id
        ticketName
        totalAmount
        ticketRecords {
          id
          amount
          remark
          createTime
        }
      }
  }
}
`

export const VOLUNTEER_VIPRECORD_QUERY = gql`
  query VolunteerVipRecordQuery($id: Int) {
    volunteers {
      vipRecords(id: $id){
        id
        startTime
        endTime
        vipLevelId
        vipLevelName
        orderId
        createTime
      }
  }
}
`

export const ANALYTICS_RFM_QUERY = gql`
  query AnalyticsRFMQuery($id: Int) {
    analytics {
      rfmAnalytics (id: $id) {
        recency
        frequency
        monetary
        recencyIndex
        frequencyIndex
        monetaryIndex
        firstOrderTime
        lastOrderTime
      }
    }
}
`

export const CONSUMPTION_TYPE_QUERY = gql`
  query ConsumptionTypeQuery($id: Int) {
    analytics {
      consumptionType(id: $id) {
        label
        sumTotal
      }
    }
}
`

export const CONSUMPTION_DISCOUNT_QUERY = gql`
  query ConsumptionDiscountQuery($id: Int) {
    analytics {
      consumptionDiscount(id: $id) {
        bonus
        redPoint
        coupon
      }
    }
}
`
