/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';

// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker';

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

// Element UI
import ElementUI from 'element-ui';
import { Notification } from 'element-ui';
import locale from 'element-ui/lib/locale/lang/zh-TW';
Vue.use(ElementUI, { locale });

// Apollo Client
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from "apollo-link-error";
import VueApollo from 'vue-apollo';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('gbadmin-app-token');
  return { headers: { ...headers, authorization: token || '' } };
})
const domain = process.env.NODE_ENV === 'production' ? 'https://crm-api.greenbox.tw' : '';
const httpLink = new HttpLink({ uri: domain + '/graphql', credentials: 'include' });
// error handling
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.map((err) => {
      if (err?.extensions?.code === "authorization") {
        sessionStorage.removeItem('userProfile');
        Notification.error({
          title: `401 Unauthorized`,
          message: `請重新登入後台(授權失效!?)`
        });
      }
      return err;
    })
    return forward(operation);
  }
  if (networkError) {
    if (networkError?.statusCode === 500) {
      Notification.error({
        title: `500 Internal Server Error`,
        message: `請檢查(網路、伺服器主機)是否正常!?`
      });
    }
    return forward(operation);
  }
  return forward(operation);
})
// create the apollo client
const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache()
});
// install the vue plugin
Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

// formatDate filter
import moment from 'moment';
moment.locale('zh-tw');
Vue.prototype.moment = moment;
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD')
  }
});
Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm')
  }
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  i18n,
  apolloProvider
});
