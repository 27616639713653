export default {
  colors: {
    default: '#344675',
    primary: '#42b883',
    info: '#1d8cf8',
    danger: '#fd5d93',
    orange: '#ff8a76',
    teal: '#00d6b4',
    primaryGradient: [
      'rgba(76, 211, 150, 0.1)',
      'rgba(53, 183, 125, 0)',
      'rgba(119,52,169,0)'
    ],
    purpleGradient: ['rgba(253,93,147,0.8)', 'rgba(253,93,147,0)']
  },
  pagination: {
    perPage: 5,
    currentPage: 1,
    perPageOptions: [5, 10, 25, 50],
    total: 0
  },
  domain: {
    gox: (process.env.NODE_ENV === 'production' ? 'https://greenbox.tw' : 'http://localhost:4638'),
    crm: (process.env.NODE_ENV === 'production' ? 'https://crm.greenbox.tw' : 'http://localhost:8080'),
    api: (process.env.NODE_ENV === 'production' ? 'https://crm-api.greenbox.tw' : 'https://localhost:7179'),
  },
  layout: [
    { "x": 0, "y": 0, "w": 5, "h":15, "i": "0", static: false, removed: false },
    { "x": 5, "y": 0, "w": 7, "h": 20, "i": "1", static: false, removed: false },
    { "x": 0, "y": 14, "w": 12, "h": 16, "i": "2", static: false, removed: false },
    { "x": 0, "y": 30, "w": 12, "h": 13, "i": "3", static: false, removed: false },
    { "x": 0, "y": 43, "w": 12, "h": 4, "i": "4", static: false, removed: false },
    { "x": 0, "y": 47, "w": 12, "h": 9, "i": "5", static: false, removed: false },
    { "x": 0, "y": 56, "w": 12, "h": 9, "i": "6", static: false, removed: false },
  /*{ "x": 0, "y": 65, "w": 12, "h": 11, "i": "7", static: false, removed: true },*/],
};
