<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <!--
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#pablo">{{ routeName }}</a>
      -->
      <a class="navbar-brand" href="/" target="CRM">CRM Dashboard</a>
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <div class="search-bar input-group">

        <el-autocomplete v-model="searchQuery"
                         :fetch-suggestions="fuzzySearch"
                         :trigger-on-focus="false"
                         value-key="name"
                         placeholder="搜尋: Email、姓名、手機"
                         @select="handleSelect">
          <template slot-scope="{ item }">
            <span>{{ item.name }} <small>{{ item.account }}</small></span>
          </template>
        </el-autocomplete>

        <div class="input-group-addon" @click="searchModalVisible=true"><i class="tim-icons icon-zoom-split"></i></div>
        <!--
          <button class="btn btn-link"
                  id="search-button"
                  data-toggle="modal"
                  data-target="#searchModal">
                  <i class="tim-icons icon-zoom-split"></i>
          </button>
        -->
        <!-- You can choose types of search input -->
      </div>
      <modal :show.sync="searchModalVisible"
             class="modal-search"
             id="searchModal"
             :centered="false"
             :show-close="true">

        <input slot="header"
               v-model="searchQuery"
               v-on:keyup.enter="onSearchVolunteer"
               type="text"
               class="form-control"
               id="inlineFormInputGroup"
               placeholder="搜尋: Email、姓名、手機、會員編號" />
      </modal>
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        title-classes="nav-link"
        class="nav-item">
        <template slot="title">
          <div class="notification d-none d-lg-block d-xl-block"></div>
          <i class="tim-icons icon-sound-wave"></i>
          <p class="d-lg-none">New Notifications</p>
        </template>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">尚無訊息通知...</a>
        </li>
      </base-dropdown>
      <base-dropdown v-if="isLoggedIn"
                     tag="li"
                     :menu-on-right="!$rtl.isRTL"
                     title-tag="a"
                     class="nav-item"
                     title-classes="nav-link"
                     menu-classes="dropdown-navbar">
        <template slot="title">
          <div class="photo"><img :title="userProfile.userName" :src="userProfile.picture || 'img/user.png'" /></div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none">Log out</p>
        </template>
        <li class="nav-link">
          <a href="#" @click="saveLayout" class="nav-item dropdown-item">儲存版面</a>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a href="#" @click="resetLayout" class="nav-item dropdown-item">重置版面</a>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
  import { BaseNav, Modal } from '@/components';
  //import SidebarToggleButton from './SidebarToggleButton';
  import { SESSION_LINK_MUTATION, SESSION_LOGOUT_MUTATION } from 'src/services/graphql';
  import { FUZZY_SEARCH_QUERY } from 'src/services/graphql';
  import * as moment from 'moment';

  export default {
    components: {
      //SidebarToggleButton,
      BaseNav,
      Modal
    },
    computed: {
      routeName() {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      },
      isRTL() {
        return this.$rtl.isRTL;
      }
    },
    data() {
      return {
        isLoggedIn: false,
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: '',
        userProfile: '',
      };
    },
    mounted() {
      this.sessionLink();
    },
    methods: {
      sessionLink() {
        if (sessionStorage.getItem('userProfile')) {
          this.userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
          this.isLoggedIn = this.userProfile.isLoggedIn;
        }
        this.login();
        this.loadLayout();
      },
      login() {
        this.$apollo
          .mutate({ mutation: SESSION_LINK_MUTATION })
          .then(response => {
            let userProfile = response.data.sessionLink;
            if (this.userProfile?.userId != userProfile.userId) {
              this.userProfile = userProfile;
              this.isLoggedIn = this.userProfile.isLoggedIn;
              sessionStorage.setItem('userProfile', JSON.stringify(this.userProfile));
              if (this.isLoggedIn === true) {
                this.$notify.success({
                  title: `歡迎回來，${userProfile.userName}`,
                  message: `上次登入時間: ${moment(String(userProfile.lastLoginTime)).format('MM/DD HH:mm A')}`,
                });
              }
            }
          })
      },
      fuzzySearch(queryString, cb) {
        let account = (queryString.indexOf(0) == '0' && !isNaN(queryString)) ? queryString : null;
        let name = (queryString.indexOf('@') <= 0 && isNaN(queryString)) ? queryString : null;
        if ((account != null && account.length > 6) || (name != null && name.length > 1)) {
          this.$apollo
            .query({
              query: FUZZY_SEARCH_QUERY,
              variables: {
                account: account,
                name: name,
              }
            })
            .then(response => {
              var results = response.data.volunteers.fuzzySearch;
              // call callback function to return suggestions
              cb(results);
            });
        }
        else {
          cb([]);
        }
      },
      handleSelect(item) {
        this.$router.push({ name: 'Dashboard', params: { id: `${item.id}` } });
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      saveLayout() {
        this.$emit('syncLayout', 'save');
      },
      loadLayout() {
        this.$emit('syncLayout', 'load');
      },
      resetLayout() {
        this.$emit('syncLayout', 'reset');
      },
      onSearchVolunteer: function () {
        console.log('this.searchQuery => ' + this.searchQuery);
        if (this.searchQuery) {
          this.searchModalVisible = false;
          this.$router.push({ name: 'Dashboard', params: { id: `${this.searchQuery}` } });
        }
      }
    }
  };
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
